@import '../../style/themes/index';
@import '../../style/mixins/index';

@dialog-prefix-cls: ~'@{ant-prefix}-modal';
@dialog-wrap-rtl-cls: ~'@{dialog-prefix-cls}-wrap-rtl';

.@{dialog-prefix-cls} {
  &-wrap {
    &-rtl {
      direction: rtl;
    }
  }

  &-close {
    .@{dialog-wrap-rtl-cls} & {
      right: initial;
      left: 0;
    }
  }

  &-footer {
    .@{dialog-wrap-rtl-cls} & {
      text-align: left;
    }
    button + button {
      .@{dialog-wrap-rtl-cls} & {
        margin-right: 8px;
        margin-left: 0;
      }
    }
  }
}

.@{dialog-prefix-cls}-centered {
  .@{dialog-prefix-cls} {
    .@{dialog-wrap-rtl-cls}& {
      text-align: right;
    }
  }
}
