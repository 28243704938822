@import '../../style/themes/index';
@import '../../style/mixins/index';

@tab-prefix-cls: ~'@{ant-prefix}-tabs';

// card style
.@{tab-prefix-cls} {
  &&-card &-card-bar &-tab &-close-x {
    .@{tab-prefix-cls}-rtl& {
      margin-right: 3px;
      margin-left: -5px;
    }
  }

  &-extra-content {
    .@{tab-prefix-cls}-rtl & {
      float: left !important;
    }
  }
}
