@import '../../style/themes/index';
@import '../../style/mixins/index';

@rate-prefix-cls: ~'@{ant-prefix}-rate';

.@{rate-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  &-star {
    &:not(:last-child) {
      .@{rate-prefix-cls}-rtl & {
        margin-right: 0;
        margin-left: 8px;
      }
    }

    &-first {
      .@{rate-prefix-cls}-rtl & {
        right: 0;
        left: auto;
      }
    }
  }

  &-text {
    .@{rate-prefix-cls}-rtl & {
      margin-right: 8px;
      margin-left: 0;
    }
  }
}
