@import '../../style/themes/index';
@import '../../style/mixins/index';
@import './card-style';

@tab-prefix-cls: ~'@{ant-prefix}-tabs';

.@{tab-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  &-ink-bar {
    .@{tab-prefix-cls}-rtl & {
      right: 0;
      left: auto;
    }
  }

  &-tab-prev,
  &-tab-next {
    &-icon {
      .@{tab-prefix-cls}-rtl & {
        right: 50%;
        left: auto;
      }
    }
  }

  &-tab-next {
    .@{tab-prefix-cls}-rtl & {
      right: auto;
      left: 2px;
    }
  }

  &-tab-prev {
    .@{tab-prefix-cls}-rtl & {
      right: 2px;
      left: auto;
    }
  }

  &-nav {
    .@{tab-prefix-cls}-tab {
      .@{tab-prefix-cls}-rtl & {
        margin: @tabs-horizontal-margin-rtl;
      }

      .@{iconfont-css-prefix} {
        .@{tab-prefix-cls}-rtl & {
          margin-right: 0;
          margin-left: 8px;
        }
      }
    }
  }
}
