@import '../../style/themes/index';
@import '../../style/mixins/index';
@import '../../input/style/mixin';

@input-prefix-cls: ~'@{ant-prefix}-input';
@select-prefix-cls: ~'@{ant-prefix}-select';
@autocomplete-prefix-cls: ~'@{select-prefix-cls}-auto-complete';

.@{autocomplete-prefix-cls} {
  .reset-component;

  // https://github.com/ant-design/ant-design/issues/22302
  .@{select-prefix-cls}-clear {
    right: 13px;
  } 
}
