@import '../../style/themes/index';
@import '../../style/mixins/index';
@import '../../input/style/mixin';

@picker-prefix-cls: ~'@{ant-prefix}-picker';

.@{picker-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  &-suffix {
    .@{picker-prefix-cls}-rtl & {
      margin-right: @padding-xs / 2;
      margin-left: 0;
    }
  }

  &-clear {
    .@{picker-prefix-cls}-rtl & {
      right: auto;
      left: 0;
    }
  }

  &-separator {
    .@{picker-prefix-cls}-rtl & {
      transform: rotate(180deg);
      transform-origin: 50% 60%;
    }
  }

  // ======================== Range =========================
  &-range {
    // Clear
    .@{picker-prefix-cls}-clear {
      .@{picker-prefix-cls}-rtl& {
        right: auto;
        left: @input-padding-horizontal-base;
      }
    }
  }

  // ======================== Ranges ========================
  &-ranges {
    .@{picker-prefix-cls}-dropdown-rtl & {
      text-align: right;
    }

    .@{picker-prefix-cls}-ok {
      .@{picker-prefix-cls}-dropdown-rtl & {
        float: left;
        margin-right: @padding-xs;
        margin-left: 0;
      }
    }
  }

  // ======================== Panel ========================
  &-panel {
    &-rtl {
      direction: rtl;
    }
  }

  &-prev-icon,
  &-super-prev-icon {
    .@{picker-prefix-cls}-panel-rtl & {
      transform: rotate(135deg);
    }
  }

  &-next-icon,
  &-super-next-icon {
    .@{picker-prefix-cls}-panel-rtl & {
      transform: rotate(-45deg);
    }
  }

  // ::before and ::after is not recommended for splitting

  &-time-panel {
    .@{picker-prefix-cls}-panel-rtl & {
      direction: ltr;
    }
  }
}
