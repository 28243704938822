@import '../../style/themes/index';
@import '../../style/mixins/index';
@import '../../checkbox/style/mixin';

@tree-prefix-cls: ~'@{ant-prefix}-tree';
@tree-node-prefix-cls: ~'@{tree-prefix-cls}-treenode';

.antTreeFn(@tree-prefix-cls) {
  .@{tree-prefix-cls} {
    &-rtl {
      direction: rtl;
    }

    // ===================== TreeNode =====================
    .@{tree-node-prefix-cls} {
      &-rtl {
        direction: rtl;
      }
    }

    // >>> Switcher
    & &-switcher {
      &_close {
        .@{tree-prefix-cls}-switcher-icon {
          svg {
            .@{tree-prefix-cls}-rtl& {
              transform: rotate(90deg);
            }
          }
        }
      }
    }
    // ==================== Show Line =====================
    &-show-line {
      // ================ Indent lines ================
      .@{tree-prefix-cls}-indent {
        &-unit {
          &::before {
            .@{tree-prefix-cls}-rtl& {
              right: auto;
              left: -@tree-title-height / 2;
              border-right: none;
              border-left: 1px solid @border-color-base;
            }
          }
        }
      }
    }
  }
}
