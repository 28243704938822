@import '../../style/themes/index';
@import '../../style/mixins/index';
@import '../../input/style/mixin';
@import '../../button/style/mixin';
@import '../../grid/style/mixin';

@form-prefix-cls: ~'@{ant-prefix}-form';
@form-item-prefix-cls: ~'@{form-prefix-cls}-item';

.@{form-prefix-cls} {
  &-rtl {
    direction: rtl;
  }
}

// ================================================================
// =                             Item                             =
// ================================================================
.@{form-item-prefix-cls} {
  // ==============================================================
  // =                            Label                           =
  // ==============================================================
  &-label {
    .@{form-prefix-cls}-rtl & {
      text-align: right;
    }

    > label {
      &.@{form-item-prefix-cls}-required::before {
        .@{form-prefix-cls}-rtl & {
          margin-right: 0;
          margin-left: 4px;
        }
      }
    }
  }

  // ==============================================================
  // =                            Input                           =
  // ==============================================================
  &-control {
    .@{ant-prefix}-col-rtl &:first-child {
      width: 100%;
    }
  }

  // status
  &-has-feedback {
    .@{ant-prefix}-input {
      .@{form-prefix-cls}-rtl & {
        padding-right: @input-padding-horizontal-base;
        padding-left: 24px;
      }
    }

    .@{ant-prefix}-input-affix-wrapper {
      .@{ant-prefix}-input-suffix {
        .@{form-prefix-cls}-rtl & {
          padding-right: @input-padding-horizontal-base;
          padding-left: 18px;
        }
      }
      .@{ant-prefix}-input {
        .@{form-prefix-cls}-rtl & {
          padding: 0;
        }
      }
    }

    .@{ant-prefix}-input-search:not(.@{ant-prefix}-input-search-enter-button) {
      .@{ant-prefix}-input-suffix {
        .@{form-prefix-cls}-rtl & {
          right: auto;
          left: 28px;
        }
      }
    }

    .@{ant-prefix}-input-number {
      .@{form-prefix-cls}-rtl & {
        padding-left: 18px;
      }
    }

    > .@{ant-prefix}-select .@{ant-prefix}-select-arrow,
    > .@{ant-prefix}-select .@{ant-prefix}-select-selection__clear,
    :not(.@{ant-prefix}-input-group-addon) > .@{ant-prefix}-select .@{ant-prefix}-select-arrow,
    :not(.@{ant-prefix}-input-group-addon)
      > .@{ant-prefix}-select
      .@{ant-prefix}-select-selection__clear {
      .@{form-prefix-cls}-rtl & {
        right: auto;
        left: 28px;
      }
    }

    > .@{ant-prefix}-select .@{ant-prefix}-select-selection-selected-value,
    :not(.@{ant-prefix}-input-group-addon)
      > .@{ant-prefix}-select
      .@{ant-prefix}-select-selection-selected-value {
      .@{form-prefix-cls}-rtl & {
        padding-right: 0;
        padding-left: 42px;
      }
    }

    .@{ant-prefix}-cascader-picker {
      &-arrow {
        .@{form-prefix-cls}-rtl & {
          margin-right: 0;
          margin-left: 17px;
        }
      }
      &-clear {
        .@{form-prefix-cls}-rtl & {
          right: auto;
          left: 28px;
        }
      }
    }

    .@{ant-prefix}-picker {
      .@{form-prefix-cls}-rtl & {
        padding-right: @input-padding-horizontal-base;
        padding-left: @input-padding-horizontal-base + @font-size-base * 1.3;
      }

      &-large {
        .@{form-prefix-cls}-rtl & {
          padding-right: @input-padding-horizontal-lg;
          padding-left: @input-padding-horizontal-lg + @font-size-base * 1.3;
        }
      }

      &-small {
        .@{form-prefix-cls}-rtl & {
          padding-right: @input-padding-horizontal-sm;
          padding-left: @input-padding-horizontal-sm + @font-size-base * 1.3;
        }
      }
    }

    &.@{form-item-prefix-cls} {
      &-has-success,
      &-has-warning,
      &-has-error,
      &-is-validating {
        // ====================== Icon ======================
        .@{form-item-prefix-cls}-children-icon {
          .@{form-prefix-cls}-rtl & {
            right: auto;
            left: 0;
          }
        }
      }
    }
  }
}

// inline
.@{form-prefix-cls}-inline {
  .@{form-prefix-cls}-item {
    .@{form-prefix-cls}-rtl& {
      margin-right: 0;
      margin-left: 16px;
    }
  }
}

// vertical
.make-vertical-layout-label() {
  .@{form-prefix-cls}-rtl& {
    text-align: right;
  }
}
